import React, {useEffect, useState} from 'react'
import {useTheme} from '../css/Styles'
import {useTranslation} from 'react-i18next';
import {PagesEnums} from '../utils/CustomEnums';
import {NavLink} from 'react-router-dom';
import {getCredentials} from '../services/UserDataService';
import AuthService from '../services/AuthService';

import {Button, Card, CardContent, Typography, useMediaQuery} from '@material-ui/core';
import i18n from "i18next";
import FetchService from "../services/FetchService";


export default function ServiceDisabled() {
    const theme = useTheme;
    const {t} = useTranslation();
    const isMobile = useMediaQuery(theme.breakpoints.down('phone'))
    const currentLanguage = i18n.language;
    const [messagePL, setMessagePL] = useState("");
    const [messageGB, setMessageGB] = useState("");


    const parseText = (text) => {
        const urlPattern = /(\bhttp[s]?:\/\/[^\s]+)/g;
        return text.split(/(\s+)/).map((word, index) => {
            if (urlPattern.test(word)) {
                return <a key={index} href={word} target="_blank" rel="noopener noreferrer">{word}</a>;
            }
            return word;
        });
    }
    
    useEffect(() => {
        FetchService.getAnnouncement("ogloszenie_e_rekrutacja", "brak_rekrutacji", "PL")
            .then(response => {

                setMessagePL(parseText(response.text));
            })
        FetchService.getAnnouncement("ogloszenie_e_rekrutacja", "brak_rekrutacji", "GB")
            .then(response => {
                setMessageGB(parseText(response.text));
            })
    }, [])

    return (
        <div style={{width: '100%', display: 'grid', placeItems: 'center'}}>
            <Card sx={{
                width: '70%',
                backgroundColor: theme.palette.additional.main,
                border: '2px solid',
                borderColor: theme.palette.secondary.main
            }} variant="outlined">
                <CardContent>
                    <Typography sx={{
                        color: theme.palette.secondary.main,
                        fontWeight: 'bold',
                        whiteSpace: 'pre-line' // This CSS property handles line breaks
                    }} fontWeight='bold'
                                variant={isMobile ? 'h7' : 'h2'}>{(currentLanguage == "pl" ? messagePL : messageGB)}
                    </Typography>
                </CardContent>
                <Button
                    sx={{
                        fontWeight: 'bold',
                        margin: 5,
                        color: theme.palette.primary.main,
                        backgroundColor: theme.palette.secondary.main,
                        '&:hover':
                            {
                                backgroundColor: theme.palette.secondary.hovered,
                                borderColor: 'none', boxShadow: 'none',
                            },
                    }
                    }
                    component={NavLink}
                    to={getCredentials() ? '' : PagesEnums.HOME.link}
                    onClick={getCredentials() ? AuthService.logout : null}
                    variant='contained'>
                    {getCredentials() ? t('header.logout') : t('mainPageButton')}
                </Button>
            </Card>

        </div>
    )
}
